<template>
    <div>
        <header-Item/>
            <div class="w-card inside_con01">
                <span id="lContenta">
                    <div class="title-name">就医指南</div>
                        <p>1、扫描大象就医App二维码，根据当前手机类型（iPhone版和Android版），下载相应的App，同时也可直接登录app.daxiang91.com进行下载；</p>
                        <p>2、未使用过大象就医的患者，输入手机号码、登录密码、真实姓名、身份证号创建大象就医健康卡，根据注册的账号，登录大象就医；</p>
                        <p>3、初次使用大象就医的患者，需先绑定您的门诊号（缴费单和医嘱可以找到，10位数字）；无门诊号或忘记之前门诊号的患者点击自动申请，系统将自动为您申请一个新的门诊号。</p>
                        <p>4、目前挂号是分时段挂号，请按挂号时段准时到医院就诊，因早到或晚到医院就诊造成就诊不便请自行承担。</p>
                        <p>5、通过手机挂号的患者到医院就诊时，如有分诊请先到各科室分诊台或导医处进行分诊；无分诊可以直接到挂号大夫诊室就诊。</p>
                        <p>6、手机支付暂时仅支持支付宝支付，医保患者可使用手机进行预约挂号和挂号并支付挂号费，就诊之后根据医嘱和医保卡到收费窗口缴纳药费及检查检验费；</p>
                        <p>7、预约挂号可在就诊日之前通过手机退费，费用自动退回原支付账户。就诊日当天只支持现场退费，退费是请先到收费窗口打印发票，到被挂号医生签字确认，再到收费窗口退费。</p>
                        <p>8、药费和检查检验费不支持手机直接退费，如需退费，请先到收费窗口打印发票，到执行科室签字确认，再到收费窗口退款。</p>
                        <p>9、使用掌上医院的患者补打发票、退费可到任意收费窗户处理。</p>
                    <p><br></p>
                    </span>
            </div>
        <FooterItem/>
    </div>
</template>
<script>
import HeaderItem from "../components/headerItem/headerItem.vue";
import FooterItem from "../components/footerItem/footerItem.vue";
export default {
    components: {
    'header-Item': HeaderItem,
    FooterItem
    },
    data() {
        return {
            
        };
        },
        methods: {
       
        }
    }
</script>
<style>
.w-card {
    width: 100%;
    border-radius: 0;
    padding: 15px;
    margin-bottom: 2rem;
    border: 0;
    display: inline-block;
    box-shadow: 0px 10px 20px 0px rgba(62, 62, 101, 0.1);
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
}
p{
    color: #888;
    line-height: 26px;
}
#lContenta {
    display: block;
    width: 70%;
    margin: 0 auto;
}
#lContenta p{
    text-align: left;
    line-height: 30px;
}
.title-name{
    width: 100px;
    padding: 10px 0;
    text-align: center;
    background-color: #208462;
    color: #ffffff;
    margin-bottom: 10px;
}
</style>