<template>
    <div>
        <header-Item/>
            <div class="w-card inside_con01">
                <span id="lContenta">
                    <div class="title-name">医院介绍</div>
                    <p style="text-indent: 2em;">自治区第三人民医院（自治区职业病医院、自治区职业病防治院）为原新疆煤矿总医院，始建于1958年10月，前身为原中国人民解放军总后勤部第二预备学校(成都军区第三军医学校)门诊部，2010年8月成为自治区卫健委（原自治区卫生厅）直属医疗机构，更名为自治区职业病医院。</p>
                    <p style="text-indent: 2em;">自治区第三人民医院为北京阜外医院、北京安贞医院、武汉大学中南医院技术协作支援医院，是国家胸痛中心、自治区儿童先心病、脑瘫定点救治医院、自治区民政厅“明天计划”定点医院、自治区工伤康复医疗定点机构，新疆“光彩明天”青少年视力康复中心。为自治区卫健委职业病诊断鉴定技术委员会办事机构、自治区职业病诊断治疗质量控制中心、自治区职业中毒应急处置专业队、高等医学院校教学医院、实习医院、自治区住院医师规范化培训基地，首批自治区新入职护士规范化培训基地、乌鲁木齐市急救中心120分站，是集医疗、教学、科研、预防、保健、康复为一体的以职业病防治为特色的三级综合医院。占地面积45437平方米，现有业务用房5.5万平方米，开放床位1000张 ，拥有万元以上装备1000余台（件），其中百万元以上设备20余台（件）。</p>
                    <p style="text-indent: 2em;">医院设置30个专业 ，设置24个病区 ，年门诊量38万人次，年出院量3万人次，年手术量1.35万人次，现有职工1050人，卫技人员占79%。其中高级职称专业人员近200人。</p><p style="text-indent: 2em;">自治区重点专科有呼吸科（职业尘肺、职业中毒）、手足显微外科（断肢断指再植）。医院重点专科有心内科、心外科、骨科（脊柱、关节）、脑病中心（神内、神外）。特色专科有眼科（白内障）、消化内科（无痛胃肠镜检查治疗）等。</p>
                    <p><br></p>
                </span>
            </div>
        <FooterItem/>
    </div>
</template>
<script>
import HeaderItem from "../components/headerItem/headerItem.vue";
import FooterItem from "../components/footerItem/footerItem.vue";
export default {
    components: {
    'header-Item': HeaderItem,
    FooterItem
    },
    data() {
        return {
            
        };
        },
        methods: {
       
        }
    }
</script>
<style>
.w-card {
    width: 100%;
    border-radius: 0;
    padding: 15px;
    margin-bottom: 2rem;
    border: 0;
    display: inline-block;
    box-shadow: 0px 10px 20px 0px rgba(62, 62, 101, 0.1);
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
}
p{
    color: #888;
    line-height: 26px;
}
#lContenta {
    display: block;
    width: 70%;
    margin: 0 auto;
}
#lContenta p{
    text-align: left;
    line-height: 30px;
}
.title-name{
    width: 100px;
    padding: 10px 0;
    text-align: center;
    background-color: #208462;
    color: #ffffff;
    margin-bottom: 10px;
}
</style>