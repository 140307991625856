<template>
    <div>
        <div id="top-header">
            <div style="max-width: 55%; -webkit-box-flex: 0; -ms-flex: 0 0 55%; flex: 0 0 55%; text-align: left; margin-left: 1rem;">
                <span style="color: #f7f7f7; font-weight: 600;">新疆维吾尔自治区第三人民医院</span>
            </div>
        </div>
        <div class="container">
            <div class="nav">
                <div class="nav-img"><img src="../../assets/logo.png" alt="err"></div>          
                <div class="nav-item">
                    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                        <el-menu-item index="1"><router-link to="/">首页</router-link></el-menu-item>
                        <el-menu-item index="2"><router-link to="/hospitalInfo">医院简介</router-link></el-menu-item>
                        <el-menu-item index="3" ><router-link to="/contactUs">新闻动态 </router-link></el-menu-item>
                        <el-submenu index="4">
                        <template  slot="title">交通导航</template>
                        <el-menu-item index="4-1">来院引导</el-menu-item>
                        <el-menu-item index="4-2">楼层引导</el-menu-item>
                        </el-submenu>
                        <el-menu-item index="5"><router-link to="/">专家介绍</router-link></el-menu-item>
                        <el-menu-item index="6"><router-link to="/contactUs">联系我们</router-link></el-menu-item>
                        <el-menu-item index="7"><router-link to="/medicalGuide">就医指南</router-link></el-menu-item>
                    </el-menu>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeIndex: '',
            activeIndex2: '1'
        };
        },
        methods: {
            handleSelect(key, keyPath) {
                console.log(key, keyPath);
            },
            toLXWM() {
                this.$router.push({
                    path: '/contactUs'
                })
            }
        }
    }
</script>
<style>
a{
    text-decoration: none;
}
#top-header {
    padding: 0;
    margin: 0;
    padding: 7px 0px;
    background-color: #208462;
    border-radius: 20px 20px 0 0;
}
.container {
        background-color: #FFFFFF;
    }
.nav{
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}
.nav-img{
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
}
.el-menu.el-menu--horizontal {
    border: none;
}
.el-carousel__item h3 {
    height: 400rpx;
    background: url('../../assets/home.jpg') no-repeat ;
    background-size: cover;
    line-height: 300px;
    margin: 0;
    color: gray;
  }
  #who-we-are {
    padding: 30px 0px 30px;
}
.el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: 2px solid #ff6a00;
    color: #303133;
}
.el-menu--horizontal>.el-menu-item {
    float: left;
    height: 60px;
    line-height: 60px;
    margin: 0;
    border-bottom: 2px solid transparent;
    color: #208462;
}
.el-menu--horizontal>.el-submenu .el-submenu__title {
    color: #208462;
}
.el-menu--horizontal>.el-menu-item:active{
    color: #ff6a00;
}
.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
    border-bottom: 2px solid #ff6a00;
    color: #303133;
}
</style>