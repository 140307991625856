<template>
    <div class="id">
        <header-Item/>
        <div class="carousel">
            <el-carousel indicator-position="outside">
                <el-carousel-item v-for="item in 4" :key="item">
                <h3>{{ item }}</h3>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div id="info-trends">
            <div class="hospital-info">
                <div class="title">
                    <b>医院简介</b>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center">
            <div class="col-lg-3">
                <div class="video-btn">
                    <img src="../assets/hospital.jpg" alt="医院简介">
                </div>
            </div>
            <div class="col-lg-9">
                <div class="about-us">
                    <div class="about-us-content" id="book-content">
                        <h3>新疆维吾尔自治区第三人民医院</h3>
                        <p>自治区第三人民医院（自治区职业病医院、自治区职业病防治院）为原新疆煤矿总医院，始建于1958年10月，前身为原中国人民解放军总后勤部第二预备学校(成都军区第三军医学校)门诊部，2010年8月成为自治区卫健委（原自治区卫生厅）直属医疗机构，更名为自治区职业病医院。</p>
                    </div>
                    <div id="what-we-book" style="">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div id="info-trends">
            <div class="hospital-info">
                <div class="title">
                    <b>新闻动态</b>
                </div>
            </div>
        </div>
        <div class="ewm">
            <div class="wxtitle">
                <div class="ewmtitle">
                    <div class="ewmname">微信公众号</div>
                    <div class="ewmgray">更多〉</div>
                </div>
                <img src="../assets/ewm.gif" >
            </div>
            <div class="ewmline"></div>
            <div>
                <div class="ewmtitle">
                    <div class="ewmname">在线问诊</div>
                    <div class="ewmgray">更多〉</div>
                </div>
                <img src="../assets/ewm2.jpg" >
            </div>
        </div>
        <div id="info-trends">
            <div class="hospital-info">
                <div class="title">
                    <b>专家介绍</b>
                </div>
            </div>
        </div>
        <div id="clients-carousel" class="owl-carousel owl-theme" style="opacity: 1; display: block;">
                    <div class="owl-wrapper-outer">
                        <div class="owl-wrapper" style="width: 1140px;  transition: all 1000ms ease 0s; transform: translate3d(0px, 0px, 0px);">
                            <div class="owl-item" style="width: 185px;">
                                <div class="item">
                                    <div class="card">
                                        <a href="http://www.zgsshh.com/" title="合作伙伴">
                                            <img src="../assets/intro1.png" alt="合作伙伴">
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="owl-item" style="width: 185px;">
                                <div class="item">
                                    <div class="card">
                                        <a href="https://www.caanet.org.cn/index.mx" title="合作伙伴">
                                            <img src="../assets/intro2.png" alt="合作伙伴">
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="owl-item" style="width: 185px;"><div class="item">
                                    <div class="card">
                                        <a href="http://www.ccagov.com.cn/" title="合作伙伴">
                                            <img src="../assets/intro3.png" alt="合作伙伴"></a>
                                    </div>
                                </div>
                            </div>
                            <div class="owl-item" style="width: 185px;"><div class="item">
                                    <div class="card">
                                        <a href="http://www.zgsshh.com/" title="合作伙伴">
                                            <img src="../assets/intro4.png" alt="合作伙伴"></a>
                                    </div>
                                </div>
                            </div>
                            <div class="owl-item" style="width: 185px;"><div class="item">
                                    <div class="card">
                                        <a href="http://www.zgsshh.com/" title="合作伙伴">
                                            <img src="../assets/intro5.png" alt="合作伙伴"></a>
                                    </div>
                                </div>
                            </div>
                            <div class="owl-item" style="width: 185px;"><div class="item">
                                    <div class="card">
                                        <a href="http://www.zgsshh.com/" title="合作伙伴">
                                            <img src="../assets/intro6.png" alt="合作伙伴"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                <div class="owl-controls clickable" style="display: none;">
                    <div class="owl-pagination">
                        <div class="owl-page active">
                            <span class=""></span>
                        </div>
                    </div>
                </div>
            </div>
        <FooterItem/>
    </div>
</template>
<script>
import HeaderItem from "../components/headerItem/headerItem.vue";
import FooterItem from "../components/footerItem/footerItem.vue";
export default {
    components: {
    'header-Item': HeaderItem,
    FooterItem
    },
    data() {
        return {
            activeIndex: '1',
            activeIndex2: '1'
        };
        },
        methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        }
        }
    }
</script>
<style>
*{
    margin: 0px;
    padding: 0px;
    border: none;
    outline: none;
    font-size: 14px;
    color: #000;
}
 #top-header {
    padding: 0;
    margin: 0;
    padding: 7px 0px;
    background-color: #208462;
    border-radius: 20px 20px 0 0;
}
.container {
        background-color: #FFFFFF;
    }
.nav{
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}
.nav-img{
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
}
.el-menu.el-menu--horizontal {
    border: none;
}
.el-carousel__item h3 {
    height: 300px;
    background: url('../assets/home.jpg') no-repeat ;
    background-size: cover;
    line-height: 300px;
    margin: 0;
    color: gray;
  }
  #who-we-are {
    padding: 30px 0px 30px;
}
.hospital-info{
    padding: 20px 0;
    max-width: 1140px;
    margin: 0 auto;
}
.title{
    text-align: center;
    margin: 0 auto;
    width: 200px;
    height: 60px;
    line-height: 50px;
    background: url('../assets/title.png') no-repeat;
    background-size: cover;
}
b{
    font-size: 30px;
    color: #FFFFFF;
}
.align-items-center {
    display: flex !important;
    justify-content: space-around;
    align-items: center;
    width: 1140px;
    margin: 0 auto;
}
.about-us-content{
    text-align: left;
    line-height: 25px;
    width: 88%;
    margin: 0 30px;
}
.about-us-content h3{
    font-size: 30px;
    margin-bottom: 15px;
    color: #000;
}
.about-us-content p{
    color: #888;
}
.video-btn{
    width: 255px;
    height: 350px;
}
.video-btn img{
    width: 100%;
    height: 100%;
}
.ewm{
    display: flex;
    justify-content: space-between;
    width: 1140px;
    margin: 0 auto;
}
.ewmtitle{
    display: flex;
    justify-content: space-between;
}
.ewmname{
    font-size: 28px;
    font-weight: blod;
}
.ewmline{
    width: 1px;
    height: 500px;
    background-color: #000;
}
.ewmgray{
    color: #888;
}
.owl-wrapper{
    display: flex;
    justify-content: space-between;
    margin: 30px auto;
}
.owl-item img{
    width: 100%;
    height: 100%;
}
#clients-carousel img {
    /* opacity: 0.4;  */
     width: 80%;
}
.card, .w-card {
    width: 100%;
    border-radius: 0;
    padding: 15px;
    margin-bottom: 2rem;
    border: 0;
    display: inline-block;
    box-shadow: 0px 10px 20px 0px rgba(62, 62, 101, 0.1);
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
}
.bottom{
    padding: 20px 0px 20px;
    background-color: #208462;
    width: 100%;
    height: 400px ;
}

</style>