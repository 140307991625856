<template>
    <div>
        <header-Item/>
            <div class="w-card inside_con01">
                <span id="lContent">
                    <div class="title-name">联系我们</div>
                    <p><span style="font-size: 16px; color: rgb(0, 0, 0);">联系电话：门诊预约0991-4598602</span></p>
                    <p><span style="font-size: 16px; color: rgb(0, 0, 0);">急诊0991-4556120</span></p>
                    <p><span style="font-size: 16px; color: rgb(0, 0, 0);">总值班 1300963120</span></p>
                    <p><span style="font-size: 16px; color: rgb(0, 0, 0);">地址：乌鲁木齐市南昌路168号</span></p>
                    <p><span style="font-size: 16px; color: rgb(0, 0, 0);">交通线路：公交乘17路、301路工业高等专科学校下车</span></p>
                    <p><span style="font-size: 16px; color: rgb(0, 0, 0);">          907路、532路到瑞安小区下车</span></p>
                    <p><br></p></span>
            </div>
        <FooterItem/>
    </div>
</template>
<script>
import HeaderItem from "../components/headerItem/headerItem.vue";
import FooterItem from "../components/footerItem/footerItem.vue";
export default {
    components: {
    'header-Item': HeaderItem,
    FooterItem
    },
    data() {
        return {
            
        };
        },
        methods: {
       
        }
    }
</script>
<style>
.w-card {
    width: 100%;
    border-radius: 0;
    padding: 15px;
    margin-bottom: 2rem;
    border: 0;
    display: inline-block;
    box-shadow: 0px 10px 20px 0px rgba(62, 62, 101, 0.1);
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
}
p{
    color: #888;
    line-height: 26px;
}
#lContent {
    display: block;
    width: 30%;
    margin: 0 auto;
    /* box-shadow: 4px 4px 4px #dbdbdb; */
}
#lContent p span{
    display: block;
    text-align: left;
    line-height: 30px;
}
.title-name{
    width: 100px;
    padding: 10px 0;
    text-align: center;
    background-color: #208462;
    color: #ffffff;
    margin-bottom: 10px;
}
</style>