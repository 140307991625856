import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import HomePage from './homePage/homePage.vue';
import HospitalInfo from './hospitalInfo/hospitalInfo.vue';
import MedicalGuide from './medicalGuide/medicalGuide.vue';
import ContactUs from './contactUs/contactUs.vue';


Vue.use(VueRouter);
Vue.use(ElementUI);


const routes = [
  { path: '/',name: 'homePage', component: HomePage },
  { path: '/hospitalInfo', component: HospitalInfo },
  { path: '/medicalGuide', component: MedicalGuide },
  { path: '/contactUs', component: ContactUs }
];
 
const router = new VueRouter({
  // mode: 'history',
  routes
});
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
